var _ = window.lodash,
     availableApps = {
  "ActiveSync/js/ActiveSync": "/usr/share/tine20/ActiveSync/ActiveSync.jsb2",
  "Addressbook/js/Addressbook": "/usr/share/tine20/Addressbook/Addressbook.jsb2",
  "Admin/js/Admin": "/usr/share/tine20/Admin/Admin.jsb2",
  "Bookmarks/js/Bookmarks": "/usr/share/tine20/Bookmarks/js/Bookmarks.js",
  "Calendar/js/Calendar": "/usr/share/tine20/Calendar/js/Calendar.js",
  "CoreData/js/CoreData": "/usr/share/tine20/CoreData/CoreData.jsb2",
  "Courses/js/Courses": "/usr/share/tine20/Courses/Courses.jsb2",
  "Crm/js/Crm": "/usr/share/tine20/Crm/Crm.jsb2",
  "EFile/js/EFile": "/usr/share/tine20/EFile/js/eFile.js",
  "EventManager/js/EventManager": "/usr/share/tine20/EventManager/js/eventManager.js",
  "ExampleApplication/js/ExampleApplication": "/usr/share/tine20/ExampleApplication/ExampleApplication.jsb2",
  "Felamimail/js/Felamimail": "/usr/share/tine20/Felamimail/Felamimail.jsb2",
  "Filemanager/js/Filemanager": "/usr/share/tine20/Filemanager/Filemanager.jsb2",
  "GDPR/js/GDPR": "/usr/share/tine20/GDPR/js/gdpr.js",
  "HumanResources/js/HumanResources": "/usr/share/tine20/HumanResources/HumanResources.jsb2",
  "Inventory/js/Inventory": "/usr/share/tine20/Inventory/Inventory.jsb2",
  "OnlyOfficeIntegrator/js/OnlyOfficeIntegrator": "/usr/share/tine20/OnlyOfficeIntegrator/js/onlyOffice.js",
  "Projects/js/Projects": "/usr/share/tine20/Projects/Projects.jsb2",
  "SSO/js/SSO": "/usr/share/tine20/SSO/js/sso.js",
  "Sales/js/Sales": "/usr/share/tine20/Sales/Sales.jsb2",
  "Setup/js/Setup": "/usr/share/tine20/Setup/js/Setup.js",
  "SimpleFAQ/js/SimpleFAQ": "/usr/share/tine20/SimpleFAQ/SimpleFAQ.jsb2",
  "Tasks/js/Tasks": "/usr/share/tine20/Tasks/Tasks.jsb2",
  "Timetracker/js/Timetracker": "/usr/share/tine20/Timetracker/Timetracker.jsb2",
  "Tinebase/js/Tinebase": "/usr/share/tine20/Tinebase/js/Tinebase.js",
  "UserManual/js/UserManual": "/usr/share/tine20/UserManual/js/UserManual.js"
},
     appResolves = {},
     appRejects = {},
     appLoadedPromises = {};
     appLoadedStates = {};

_.each(availableApps, function(index,key) {
  var appName = key.replace(/\/.*$/, "");
  appLoadedStates[appName] = "pending";
  appLoadedPromises[appName] = new Promise(function(resolve, reject) {
    appResolves[appName] = resolve;
    appRejects[appName] = reject;
  });
});

module.exports = {
  loadAllApps: function(userApps) {
    var pms = _.reduce(userApps, function(p, app) {
      var appName = "ActiveSync"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "ActiveSync/js/ActiveSync" */
          "/usr/share/tine20/ActiveSync/ActiveSync.jsb2"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "Addressbook"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "Addressbook/js/Addressbook" */
          "/usr/share/tine20/Addressbook/Addressbook.jsb2"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "Admin"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "Admin/js/Admin" */
          "/usr/share/tine20/Admin/Admin.jsb2"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "Bookmarks"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "Bookmarks/js/Bookmarks" */
          "/usr/share/tine20/Bookmarks/js/Bookmarks.js"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "Calendar"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "Calendar/js/Calendar" */
          "/usr/share/tine20/Calendar/js/Calendar.js"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "CoreData"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "CoreData/js/CoreData" */
          "/usr/share/tine20/CoreData/CoreData.jsb2"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "Courses"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "Courses/js/Courses" */
          "/usr/share/tine20/Courses/Courses.jsb2"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "Crm"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "Crm/js/Crm" */
          "/usr/share/tine20/Crm/Crm.jsb2"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "EFile"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "EFile/js/EFile" */
          "/usr/share/tine20/EFile/js/eFile.js"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "EventManager"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "EventManager/js/EventManager" */
          "/usr/share/tine20/EventManager/js/eventManager.js"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "ExampleApplication"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "ExampleApplication/js/ExampleApplication" */
          "/usr/share/tine20/ExampleApplication/ExampleApplication.jsb2"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "Felamimail"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "Felamimail/js/Felamimail" */
          "/usr/share/tine20/Felamimail/Felamimail.jsb2"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "Filemanager"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "Filemanager/js/Filemanager" */
          "/usr/share/tine20/Filemanager/Filemanager.jsb2"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "GDPR"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "GDPR/js/GDPR" */
          "/usr/share/tine20/GDPR/js/gdpr.js"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "HumanResources"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "HumanResources/js/HumanResources" */
          "/usr/share/tine20/HumanResources/HumanResources.jsb2"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "Inventory"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "Inventory/js/Inventory" */
          "/usr/share/tine20/Inventory/Inventory.jsb2"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "OnlyOfficeIntegrator"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "OnlyOfficeIntegrator/js/OnlyOfficeIntegrator" */
          "/usr/share/tine20/OnlyOfficeIntegrator/js/onlyOffice.js"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "Projects"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "Projects/js/Projects" */
          "/usr/share/tine20/Projects/Projects.jsb2"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "SSO"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "SSO/js/SSO" */
          "/usr/share/tine20/SSO/js/sso.js"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "Sales"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "Sales/js/Sales" */
          "/usr/share/tine20/Sales/Sales.jsb2"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "Setup"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "Setup/js/Setup" */
          "/usr/share/tine20/Setup/js/Setup.js"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "SimpleFAQ"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "SimpleFAQ/js/SimpleFAQ" */
          "/usr/share/tine20/SimpleFAQ/SimpleFAQ.jsb2"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "Tasks"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "Tasks/js/Tasks" */
          "/usr/share/tine20/Tasks/Tasks.jsb2"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "Timetracker"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "Timetracker/js/Timetracker" */
          "/usr/share/tine20/Timetracker/Timetracker.jsb2"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "Tinebase"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "Tinebase/js/Tinebase" */
          "/usr/share/tine20/Tinebase/js/Tinebase.js"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

      var appName = "UserManual"; 
      if(app.name == appName) {
        return p.then(function() {return import(
          /* webpackChunkName: "UserManual/js/UserManual" */
          "/usr/share/tine20/UserManual/js/UserManual.js"
        )}).then(function() {
          appLoadedStates[appName] = "fulfilled";
          appResolves[appName]();
        }).catch(function() {
          appLoadedStates[appName] = "rejected";
          appRejects[appName]();
        });
      }

    return p;
    }, Promise.resolve());
    return pms;
  },
  appLoadedStates: appLoadedStates,
  appLoadedPromises: appLoadedPromises
}
